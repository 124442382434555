@import url('https://fonts.googleapis.com/css?family=Poppins');

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  color: #042B5F;
  /*background-color: #E80000;*/
  font-family: 'Poppins', sans-serif;
  max-width: 100%;
  width: 100%;
  min-height: 100dvh;
  hyphens: auto;
}

.pagefiller {
  flex: 1;
  height: 1px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.App h1 {
  text-align: center;
}

.textcontainer {
  width: 100%;
  text-align: center;
}

.App span {
  font-weight: bold;
}

.buttoncontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 50px;
}

.button {
  aspect-ratio: 4/3;
  width: fit-content;
  background-color: #042B5F;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
  max-width: 95%;
  overflow-wrap: break-word;
  hyphens: auto;
  transition: all 0.35s ease-out;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.button:hover {
  transform: scale(1.03);
  transition: all 0.1s ease-in;
}

.otherbutton {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #042B5F;
  color: white;
  padding: 10px 30px;
  border-radius: 10000px;
  cursor: pointer;
  transition: all 0.35s ease-out;
  width: fit-content;
  margin: 0 auto;
  margin-top: 20px;
}

.otherbutton:hover {
  transform: scale(1.03);
  transition: all 0.1s ease-in;
}

.otherbutton:hover h3 {
  text-decoration: underline;
}

.otherbutton h3 {
  margin: 0;
  padding: 0;
}

.button h2 {
  margin: 0;
  font-size: 1.5rem;
  padding: 10px 30px;
  background-color: #E80000;
  color: white;
  border-radius: 10000px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.button svg {
  color: white;
  width: 350px;
  max-width: 100%;
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 15px;
  max-width: 100%;
  width: 70%;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10000px;
  padding: 10px 30px;
  gap: 20px;
}

.card .id {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card .id h3,
.card .id h4 {
  margin: 0;
  padding: 0;
}

.card .id .h4 {
  color: #E80000;
}

.infobutton {
  background-color: #042B5F;
  color: white;
  padding: 5px 10px;
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  border: solid 3px;
  border-color: #ffffff90;
  cursor: pointer;
  transition: all 0.35s ease-out;
  box-sizing: border-box;
}

.infobutton:hover {
  transform: scale(1.03);
  border-color: #ffffff00;
}

.infobutton:active {
  transform: scale(0.95);
  transition: all 0.1s ease-in;
}

.infobutton>h3 {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactbutton {
  background-color: #075E54;
  color: white;
  padding: 5px 10px;
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  border: solid 3px #25D36690;
  cursor: pointer;
  transition: all 0.35s ease-out;
  box-sizing: border-box;
}

.contactbutton:hover {
  border: solid 3px #25D36600;
  transition: all 0.15s ease-in;
  color: #25D366;
}

.contactbutton:active {
  transform: scale(0.95);
  transition: all 0.1s ease-in;
}

.contactbutton>h3 {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unavailablebutton {
  background-color: #E80000;
  color: white;
  padding: 5px 10px;
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  border: solid 3px #00000030;
  cursor: not-allowed;
  transition: all 0.35s ease-out;
  box-sizing: border-box;
}

.unavailablebutton>h3 {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  backdrop-filter: blur(10px) brightness(0.8);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  overflow-y: auto;
}

.popup .container {
  background-color: white;
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  border-radius: 25px;
  border: solid 2px #042B5F;
}

.popup .container .logo {
  margin: 0 auto;
  max-width: 100%;
  max-height: 150px;
  width: fit-content;
  height: fit-content;
}

.popup .container .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.popup .container .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #042B5F;
  color: white;
  overflow: hidden;
  border-radius: 23px 23px 0 0;
}

.popup .container .title h2 {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-right: 15px;
}

nav .logo {
  max-width: 200px;
}

nav .logo img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.popup .container .title h2>* {
  flex-shrink: 0;
}

.closepopup {
  border: none;
  background-color: white;
  color: #042B5F;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10000px;
  cursor: pointer;
}

.popup .container span {
  font-weight: 800;
}

.popup .container h3 {
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
}

.popup .container .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 25px;
  padding: 15px;
}

.popup .container .content .text {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: solid 2px #042B5F;
  padding: 15px;
  border-radius: 15px;
}

.popup .container .sharebt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: solid 2px #042B5F;
  width: fit-content;
  margin: 0 auto;
  padding: 5px 10px;
  border-radius: 10000px;
  cursor: pointer;
}

.buttonssharesum h3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0px 10px;
  width: fit-content;
}

.buttonssharesum>* {
  flex: 1;
  box-sizing: border-box;
}

.buttonssharesum .sharebt:hover {
  transform: scale(1.03);
  transition: all 0.1s ease-in;
}

.buttonssharesum .sharebt:active {
  transform: scale(0.95);
  transition: all 0.1s ease-in;
}

.buttonssharesum .sharebt {
  transition: all 0.35s ease-out;
}

.buttonssharesum .negotiatesumbt {
  background-color: #075E54;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: fit-content;
  margin: 0 auto;
  padding: 5px 10px;
  border-radius: 10000px;
  cursor: pointer;
  transition: all 0.35s ease-out;
}

.buttonssharesum .negotiatesumbt:hover {
  transform: scale(1.03);
  transition: all 0.1s ease-in;
}

.buttonssharesum .negotiatesumbt:active {
  transform: scale(0.95);
  transition: all 0.1s ease-in;
}

.buttonssharesum {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.commandbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  flex-wrap: wrap;
}

.commandbar h2 {
  margin: 0;
  padding: 0;
  margin-right: auto;
}

.filterbt {
  background-color: #042B5F;
  color: white;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  cursor: pointer;
  transition: all 0.35s ease-out;
  box-sizing: border-box;
  position: relative;
}

.filters {
  width: 10px;
  height: 10px;
  background-color: #E80000;
  border-radius: 100%;
  position: absolute;
  top: 2px;
  right: 2px;
}

.filterbt:hover {
  transform: scale(1.03);
  transition: all 0.1s ease-in;
}

.filterbt:active {
  transform: scale(0.95);
  transition: all 0.1s ease-in;
}

.filterbt h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 0;
  padding: 0;
}

.filteritem {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: baseline;
  padding: 5px 10px;
}

.filteritem span {
  font-weight: 600;
  font-size: 1rem;
}

.filteritem input {
  border: none;
  outline: none;
  border-bottom: solid 2px #042B5F;
  padding: 5px 10px;
  color: #042B5F;
  font-weight: 600;
  font-size: 1rem;
  width: 15ch;
  flex: 1;
}

.filteritem select {
  border: solid 2px #042B5F;
  padding: 5px 10px;
  border: none;
  outline: none;
  border-bottom: solid 2px #042B5F;
  color: #042B5F;
  font-weight: 600;
  font-size: 1rem;
  width: 15ch;
  flex: 1;
}

.search {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border: solid 2px #042B5F;
  border-radius: 1000px;
  height: fit-content;
  background-color: #042B5F;
  color: white;
  cursor: pointer;
  transition: flex-grow 0.35s ease-out, padding 0s ease-out 0.35s, padding-inline 0.35s ease-out;
}

.search:focus-within,
.showsearch {
  flex-grow: 1;
  transition: flex-grow 0.35s ease-out, padding 0s ease-out 0.35s, padding-inline 0.35s ease-out;
}

.search input {
  border: none;
  outline: none;
  padding: 5px 10px;
  color: #042B5F;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 1000px;
  font-size: 1.17rem;
  font-family: 'Poppins', sans-serif;
  width: 0px;
  padding: 0px;
  padding-inline: 0px;
  flex-grow: 0;
}

.search input:focus,
.showsearch input {
  flex-grow: 1;
  padding: 5px;
  padding-inline: 10px;
  margin-left: auto;
}

.search h3 {
  margin: 0;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.App header {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.App header nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #042B5F;
  color: white;
  width: 100%;
  gap: 25px;
  flex-wrap: wrap;
  padding-inline: 30px;
}

.App header span {
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.App header nav>*:first-child {
  margin-right: auto;
}

.App footer {
  height: fit-content;
  background-color: #042B5F;
  color: white;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App footer a {
  text-decoration: none;
  font-style: italic;
  color: white;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.App footer p {
  margin: 0;
  padding: 0;
}

.card .checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 1.5rem;
  border: solid 2px #042B5F;
  width: fit-content;
  border-radius: 1000px;
  color: white;
}

.card .checkbox input {
  width: 15px;
  height: 15px;
  background-color: #00000000;
  border-color: #00000000;
  outline-color: #00000000;
  appearance: none;
  position: relative;
}

.checkbox input:checked::before {
  content: '✔';
  font-size: 1.5rem;
  color: #042B5F;
  position: absolute;
  top: -12px;
  left: -1px;
}

.sumbutton {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: #042B5F;
  color: white;
  padding: 10px 20px;
  border-radius: 10000px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-size: 1.2rem;
}

.installmentssum {
  font-size: 1.17rem;
}

.legenda {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.legenditem > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 1000px;
}

.statusdisponivel {
  background-color: #075E54;
  color: white;
  padding: 0px 10px;
}

.statusindisponivel {
  background-color: #E80000;
  color: white;
  padding: 0px 10px;
}



.legenda h4,
.legenda h5 {
  margin: 0;
  padding: 0;
}

.selectionmanagement {
  display: flex;
  flex-direction: row;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.selectionmanagement > div {
  background-color: #042B5F;
  color: white;
  padding: 5px 15px;
  border-radius: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .card {
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;
  }

  .App header nav {
    flex-direction: column;
  }

  .App header nav>*:first-child {
    margin: 0;
  }
}

@media (max-width: 800px) {

  .commandbar {
    flex-direction: column;
    gap: 10px;
  }

  .selectionmanagement > div {
    flex: 1;
  }

  .App {
    padding: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }

  .cards {
    width: 100%;
    box-sizing: border-box;
  }

  .button {
    width: 100%;
  }

  .button h2 {
    padding: 10px;
  }

  .button svg {
    width: 100%;
  }

  .card {
    flex-direction: column;
    border-radius: 25px;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .card .id {
    width: 100%;
  }

  .card .id h3,
  .card .id h4 {
    text-align: center;
  }

  .card .id .h4 {
    color: #E80000;
  }

  .infobutton {
    width: 100%;
  }

  .contactbutton {
    width: 100%;
  }

  .unavailablebutton {
    width: 100%;
  }

  .popup .container {
    height: fit-content;
    overflow: unset;
  }

  .filteritem input {
    width: 100%;
    min-width: 8ch;
  }

}